@import '../ui_imports';

.padded-page {
  &__headers {
    @include ui-page-headers;
  }

  &__content {
    @include ui-page-row-margins;
  }

  &__col-content {
    @include ui-page-column;
  }
}

