@import '~bootstrap/scss/nav';

.nav-link {
  &.disabled {
    cursor: not-allowed;
  }
}

.nav-tabs {
  font-weight: $headings-font-weight;
}
