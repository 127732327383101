@import '~bootstrap/scss/forms';
@import '../../ui_imports';
@import '~@user-interviews/ui-design-system/scss/forms';

.invalid-feedback {
  font-size: 100%;

  &__list {
    @include ui-remove-list-styles;
  }

  .is-invalid ~ & {
    display: block; // Don't require form-control to show invalid feedback next to is-invalid
  }
}

input[type="number"].no-spinner {
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.form-group-legend {
  display: block;
  margin-top: .5rem;

  &--inline {
    display: inline-block;
    margin-left: .5rem;
  }
}

.form-check {
  margin-bottom: .25rem;
}

.form-check-label {
  color: var(--ux-gray-800);
}

.form-check-input:disabled {
  color: var(--ux-gray-400);
}

.form-check-input:disabled ~ .form-check-label {
  color: var(--ux-gray-400);
}

// Matches the DS Select components (eventually want to swap these out for DS)
.form-select {
  @include font-type-30;

  border: 1px solid var(--ux-gray-400);

  cursor: pointer;
  min-height: 2.375rem;

  &:focus {
    border: 1px solid var(--ux-blue);
    box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
    filter: drop-shadow(0 0 3px rgba(102, 175, 233, 70%))
  }
}

.ui-form {
  h1 {
    margin-bottom: 2rem;
  }

  .ReactDateTimePicker {
    width: 100%;
  }

  label {
    align-items: center;
    display: flex;
  }

  .date-time-picker {
    width: 100%;
  }

  .form-actions {
    .submit {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }
    }
  }

  .form-group {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
  }

  .form-control {
    height: 2.25rem;
  }

  .form-description {
    margin: .5rem 0 0;
  }

  .form-label {
    @include font-type-30--bold;
    margin-bottom: 0.5rem;
  }

  .radio {
    margin-top: 0;
  }

  &__section-divider {
    margin: 1rem 0;

    @include media-breakpoint-up(sm) {
      margin: 1.5rem 0;
    }
  }

  h2, &__section-header {
    font-size: 1.5rem;
  }

  &--participant {
    .form-control:focus {
      border: 0.06rem solid var(--ux-blue-500);
      box-shadow: $input-focus-box-shadow;
      color: $input-focus-color;
      filter: drop-shadow(0 0 3px rgba(102, 175, 233, 70%));
    }
  }
}

.Form {
  h1, &__header {
    @include font-type-70--bold;
    margin-bottom: 2rem;
  }

  &__actions { // .form-actions
    .submit {
      width: 100%;

      @include media-breakpoint-up(sm) {
        width: 50%;
      }
    }
  }

  .btn-full {
    display: block;
    width: 100%;
  }

  &__instructions {
    margin: 1rem 0 0 0.5rem;
  }

  &__section-divider {
    margin: 1rem 0;

    @include media-breakpoint-up(sm) {
      margin: 1.5rem 0;
    }
  }

  &--centered {
    flex-direction: column;
    text-align: center;

    .FormControlLabel {
      display: inline;
    }
  }

  &--participant {
    .form-control:focus {
      border-color: $ux-participant;
      box-shadow: inset 0 1px 1px $ux-participant, 0 0 8px $ux-participant;
    }
  }
}

.FormGroup {
  // Overrides to fix annoyances with our date time picker library
  .ReactDateTimePicker {
    min-width: 0;
    width: 100%;
  }

  .date-time-picker {
    min-width: 0;
    width: 100%;
  }

  .SingleSelect, .Select, .AsyncSelect {
    min-width: 0;
    width: 100%;
  }
}
