.ui-popper {
  @include font-type-30;
  background-color: var(--ux-white);
  border-radius: var(--ux-border-radius);
  border: 1px solid var(--ux-gray-300);
  box-shadow: var(--ux-box-shadow-card);
  max-width: 15rem;
  letter-spacing: normal;
  padding: 0.75rem;
  z-index: var(--z-index-flash);

  &__header {
    border-bottom: 1px solid var(--ux-gray-300);
    margin-bottom: 0.5rem;
  }

  &--dark {
    @include font-type-30--bold;
    background-color: var(--ux-gray-800);
    color: var(--ux-gray-100);
  }
}
