@import '~common/ui_imports';

.page-section__navigation {
  $block: #{&};

  $tab-bar-border-radius: 0;
  padding: 0;

  text-align: center;

  &__tab {
    display: inline-block;
    min-width: 13rem;

    background-color: var(--ux-white);
    outline: solid 1px var(--ux-gray-400);
    color: var(--synth-primary-cta-blue);


    button {
      @include ui-remove-button-styles;

      display: block;
      font-weight: $font-weight-bold;
      line-height: 1.5;
      padding: .5rem 1rem;
      width: 100%;

      border: 0;
      cursor: pointer;
      text-decoration: none;
    }

    &--filled {
      background-color: $ux-gray-light;
      color: var(--ux-gray-500);
      min-width: unset;
      outline: none;

      button {
        font-weight: $headings-font-weight;
        height: 100%;
        padding: 0.5rem 1.25rem;
      }

      + #{$block}__tab--filled {
        border-left: 2px solid var(--ux-white);
      }
    }

    &--active {
      background-color: var(--synth-primary-cta-blue);
      color: var(--ux-white);

      &:hover {
        color: var(--ux-white);
      }
    }

    &--completed {
      background-color: var(--ux-white);
      color: $ux-primary;
    }
  }

  &__utility-button {
    &--left {
      margin-right: auto;
    }

    &--right {
      margin-left: auto;
    }

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &--fixed {
    align-items: center;
    background-color: var(--ux-white);
    border-top: 1px solid var(--ux-gray-300);
    box-shadow: var(--ux-box-shadow);
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    height: $ui-page-section-navigation-height;
    justify-items: center;
    left: 0;
    margin: 0;
    padding: 0.25rem .875rem;
    position: fixed;
    top: $header-height-xs;
    width: 100%;
    z-index: var(--z-index-fixed);

    #{$block}__tabs {
      @include list-unstyled;
      display: grid;
      grid-column: 1;
      grid-auto-flow: column;
      grid-column-start: 2; // Nav is split into 3 sections, tabs are _always_ center
      align-self: center;
      white-space: nowrap;

      border-radius: 1.5rem;
      margin: 0 auto;
      overflow: hidden;
    }

    #{$block}__nav-button {
      display: none;
      background-color: $ux-gray-light;
      border: 0;

      button {
        color: var(--ux-gray-500);
        height: 100%;
        width: 100%;

        &:active, &:focus {
          box-shadow: none;
        }
      }
    }

    @include media-breakpoint-up(sm) {
      top: $header-height;
    }

    @media (max-width: 896px) {
      #{$block}__nav-button {
        display: block;
      }

      #{$block}__tab {
        cursor: default;
        pointer-events: none;
        min-width: 12rem;

        &:not(#{$block}__tab--active) {
          display: none;
        }
      }
    }
  }
}
