@mixin ui-page-column($size: normal) {
  @include make-col-ready;
  @include make-col(12);

  @if ($size == narrow) {
    @include media-breakpoint-up(md) {
      @include make-col(8);
      @include make-col-offset(2);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(6);
      @include make-col-offset(3);
    }
  } @else if ($size == wide) {
    @include media-breakpoint-up(lg) {
      @include make-col(10); // 8
      @include make-col-offset(1); // 2
    }
  } @else {
    @include media-breakpoint-up(md) {
      @include make-col(10);
      @include make-col-offset(1);
    }
    @include media-breakpoint-up(lg) {
      @include make-col(8);
      @include make-col-offset(2);
    }
  }
}

@mixin ui-page-headers() {
  @include ui-page-row-margins;
  text-align: center;

  h5 {
    font-weight: $font-weight-base;
  }
}

@mixin ui-page-cta-padding() {
  padding-bottom: $ui-ctabar-height + $card-xs-spacing;
}

@mixin ui-page-row-margins($top: true, $bottom: true) {
  @if ($top) {
    margin-top: $ui-page-spacing-mobile;

    @include media-breakpoint-up(sm) {
      margin-top: $ui-page-spacing;
    }
  }

  @if ($bottom) {
    margin-bottom: $ui-page-spacing-mobile;

    @include media-breakpoint-up(sm) {
      margin-bottom: $ui-page-spacing;
    }
  }
}
