// This file is only for the most site layout concerns
@import '../ui_imports';

body {
  padding-top: $ui-navbar-height;

  @include media-breakpoint-down(md) {
    padding-top: $ui-navbar-height-mobile;
  }

  a {
    @include transition($btn-transition);

    color: $link-color;
    text-decoration: none;
    transition-duration: 66.7ms, 66.7ms, 66.7ms, 66.7ms, 66.7ms;

    &:hover {
      color: $link-hover-color;
      text-decoration: underline;
    }
  }

  input, textarea, .form-control {
    &::placeholder {
      color: $ux-placeholder;
    }
  }

  &.participant {
    a {
      color: $ux-participant;
      &:hover {
        color: $ux-participant-dark;
      }
    }
  }

  &.no-header {
    padding-top: 0;
  }

  &.gradient-cream {
    @include ui-background-gradient-cream;
  }

  &.gradient-green {
    @include ui-background-gradient-green;
  }

  &.gray-100 {
    background-color: var(--ux-gray-100);
  }

  &.ux-cream {
    background: var(--ux-cream);
  }
}

#ui-window-root {
  min-height: 100%;
}

// .ui-content-root is the root of interactive content associated only with a
// particular view. It does not include the primary nav or flash messages, while
// #ui-window-root does.

.ui-content-root {
  @include ui-full-view-height($ui-navbar-height);

  @include media-breakpoint-down(md) {
    @include ui-full-view-height($ui-navbar-height-mobile);
  }

  &--content-fluid {
    height: auto;
  }

  &--no-header {
    @include ui-full-view-height;
  }

  &--no-padding {
    padding: 0;
  }

  &.gray-100 {
    background-color: var(--ux-gray-100);
  }

  &--overflow-auto {
    overflow: auto;
    scrollbar-width: none; /* For Firefox */

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }
  }
}
