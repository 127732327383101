$body-fonts: Inter, sans-serif;
$header-fonts: Inter, sans-serif;

// colors to use -- note, look to update `colors.js` when changing these
$ux-white: #FFF;
$ux-black: #000;

$ux-blue: #3F6DCA;
$ux-navy: #011936;
$ux-cream: #F8F4F2;
$ux-emerald: #158D71;
$ux-gray: #A1A1A1;
$ux-green: #04713C;
$ux-yellow: #F3CE14;
$ux-red: #E03131;
$ux-orange: #F59C27;

$brand-color-facebook: #4C67A1;
$brand-color-google: #4285F4;
$brand-color-linkedin: #0077B5;
$brand-color-office365: #2372BA;
$brand-color-twitter: #1B95E0;
$brand-color-yahoo: #4D00AE;

$ux-gray-light: #F5F5F5; // TODO: Review uses of this and move to gray-100 or gray-200

$ux-blue-checkbox: #164ECF; // TODO: This needs to change in the Design System

// Colors should match palette.scss in 'ui-design-system'
$ux-blue-100: #F1F5FE;
$ux-blue-200: #BDD1FB;
$ux-blue-300: #A6C0F5;
$ux-blue-400: #7497DF;
$ux-blue-500: $ux-blue;
$ux-blue-600: #2854AF;
$ux-blue-700: #143C8D;
$ux-blue-800: #0B2D72;
$ux-blue-900: #031D52;

$ux-emerald-100: #BFDDD5;
$ux-emerald-200: #91CABB;
$ux-emerald-300: #65B8A2;
$ux-emerald-400: #3EA388;
$ux-emerald-500: $ux-emerald;
$ux-emerald-600: #177863;
$ux-emerald-700: #156152;
$ux-emerald-800: #0D4A3E;
$ux-emerald-900: #073B31;

$ux-gray-100: #F9F9F9;
$ux-gray-200: #F1F1F1;
$ux-gray-300: #E1E1E1;
$ux-gray-400: #D1D1D1;
$ux-gray-500: $ux-gray;
$ux-gray-600: #818181;
$ux-gray-700: #616161;
$ux-gray-800: #444;
$ux-gray-900: #101010;

$ux-green-100: #D4F6E5;
$ux-green-200: #A3F9CF;
$ux-green-300: #70E2AB;
$ux-green-400: #40CC88;
$ux-green-500: #21B36C;
$ux-green-600: #09914F;
$ux-green-700: $ux-green;
$ux-green-800: #015029;
$ux-green-900: #003119;

$ux-navy-100: #C4D0DF;
$ux-navy-200: #A3BAD2;
$ux-navy-300: #7396BA;
$ux-navy-400: #40669C;
$ux-navy-500: #234574;
$ux-navy-600: #16325A;
$ux-navy-700: $ux-navy;
$ux-navy-800: #01152D;
$ux-navy-900: #021020;

$ux-orange-100: #FDEAD2;
$ux-orange-200: #FCDFB9;
$ux-orange-300: #FAC988;
$ux-orange-400: #F7B258;
$ux-orange-500: $ux-orange;
$ux-orange-600: #E18C28;
$ux-orange-700: #AE6608;
$ux-orange-800: #7D4A06;
$ux-orange-900: #653B05;

$ux-red-100: #FAE1E1;
$ux-red-200: #F3BDBD;
$ux-red-300: #EB7A7A;
$ux-red-400: #E44E4E;
$ux-red-500: $ux-red;
$ux-red-600: #C71F1F;
$ux-red-700: #B11B1B;
$ux-red-800: #9B1818;
$ux-red-900: #470404;

$ux-yellow-100: #FFF1AB;
$ux-yellow-200: #FFEB85;
$ux-yellow-300: #FFE356;
$ux-yellow-400: #FBDA36;
$ux-yellow-500: $ux-yellow;
$ux-yellow-600: #E1BE0C;
$ux-yellow-700: #CAA900;
$ux-yellow-800: #A98D01;
$ux-yellow-900: #453A00;

// used as the primary color, expect it to change
$ux-primary: $ux-emerald;
$ux-researcher: $ux-emerald;
$ux-researcher-light: #BFDDD5;
$ux-participant: $ux-emerald;
$ux-participant-dark: darken($ux-participant, 15%);
$ux-save: $ux-green;
$ux-edit: $ux-gray-600;
$ux-cancel: $ux-red;

$ux-divider: #e1e1e1;
$ux-success: $ux-green;
$ux-error: $ux-red;
$ux-warning: $ux-yellow;

// Flash colors
$ux-flash-error: $ux-red-100;
$ux-flash-error-border: $ux-red-200;

$ux-navbar-shadow-color: rgba( 0, 0, 0, 26% );
$ux-box-shadow: 0 2px 5px $ux-navbar-shadow-color;
$ux-box-shadow-light: 0 1px 1px $ux-navbar-shadow-color;

$ux-box-shadow-card: 0 2px 4px rgba(0,0,0,10%);

$ux-box-shadow-top: 0 -2px 5px $ux-navbar-shadow-color;
$ux-box-shadow-top-light: 0 -1px 1px $ux-navbar-shadow-color;

// Common dimensions

$ux-border-radius: 0.25rem;

// Navbar
$navbar-default-bg: $ux-white;

// Navbar dimensions
$navbar-height: 48px;

// Navbar links
$nav-link-font-weight: 500;
$nav-link-text-transform: none;
$nav-dropdown-font-weight: 300;
$nav-dropdown-text-transform: none;

$grid-float-breakpoint: 960px;
$header-height: $navbar-height;
$header-height-xs: 48px;

// This zindex is between the header (zindex-fixed) and the modal backdrop (zindex-modal-backdrop)
$z-index-flash: 1045;
