@import '../ui_imports';

.error-explanation {
  color: var(--ux-red);

  ul {
    list-style: none;
    margin: 0 0 1rem;
  }
}

// This is here after fighting a webpack SCSS chunking error for 2+ hours and I
// just need to get AppSignal rolling, will revisit via eslint-plugin-import
.ui-error-boundary-fallback {
  background: $ux-error;
  border: .25rem dashed $ux-warning;
  border-radius: .5rem;
  color: white;
  padding: .5rem;
}
