@import '../ui_imports';

// TODO BHS Remove content-well and form-well some day
$content-well-border-radius: var(--ux-border-radius);

.content-well {
  border: 1px solid var(--ux-gray-400);
  border-radius: $content-well-border-radius;
  padding: 0 1rem;

  background-color: var(--ux-white);

  &--no-border {
    border: none;
    padding: 0;
  }

  & ~ .content-well {
    margin-top: 1.5rem;
  }

  &__header {
    position: relative;

    margin: 1rem 0;

    font-family: $headings-font-family;
    font-size: 1.5rem;
    text-align: center;

    &__controls {
      display: flex;
      position: absolute;
      right: 0;

      align-items: center;
      height: 100%;
    }

    .fa {
      cursor: pointer;

      height: 2rem;
      width: 2rem;

      border-radius: 50%;
      margin-left: .5rem;
      padding: .5rem;

      font-size: 1rem;
      text-align: center;
    }

    .fa-pencil {
      background-color: $ux-edit;
      color: var(--ux-white);
    }

    .fa-pencil {
      background-color: var(--ux-white);
      color: $ux-edit;
    }

    .fa-check {
      background-color: $ux-save;
      color: var(--ux-white);
    }

    .fa-close {
      background-color: $ux-cancel;
      color: var(--ux-white);
    }
  }

  &__edit-controls {
    @extend .edit-only;

    margin: 1rem 0;

    text-align: center;

    .btn {
      width: 6rem;
      margin: 0 .5rem;
    }
  }

  &__buttons {
    margin: 1rem 0;
    text-align: center;

    .btn {
      margin-left: .25rem;
      margin-right: .25rem;
    }
  }

  &__detail {
    background-color: var(--ux-gray-100);
    border-radius: $content-well-border-radius;

    margin: 1rem 0;
    padding: 1rem 0;

    &__detail-label {
      @include ui-heading-fonts;
      font-size: 1rem;

      .control-label {
        text-align: left;
      }
    }
  }

  &.view {
    .edit-only {
      display: none;
    }

    .control-label {
      padding-top: 0;
    }
  }

  &.edit {
    .view-only {
      display: none;
    }
  }
}
