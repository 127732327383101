@mixin ui-fixed($side: top) {
  position: fixed;
  z-index: var(--z-index-fixed);

  @if ($side == top or $side == bottom) {
    left: 0;
    right: 0;
  } @else {
    bottom: 0;
    top: 0;
  }

  @if ($side == top) {
    top: 0;
  } @else if ($side == right) {
    right: 0;
  } @else if ($side == bottom) {
    bottom: 0;
  } @else {
    left: 0;
  }
}
