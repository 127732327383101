/*
 Note this isn't really a bootstrap extension, I just didn't want badge import code to live in
 multiple places... I think we'll eventually just kill the bootstrap one as it is barely used?
 */
@import '~bootstrap/scss/badge';

$ui-badge-themes: (
  'blue': (
    background: var(--ux-blue-100),
    border-color: var(--ux-blue-200),
    color: var(--ux-blue-700),
  ),
);

.ui-badge {
  @include ui-badge;

  @each $colorScheme, $theme in $ui-badge-themes {
    &--#{$colorScheme} {
      @include ui-badge-color($theme: $theme);
    }
  }
}
