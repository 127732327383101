// This should be included directly on the ul element
@mixin ui-remove-list-styles($itemTop: 0) {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    margin: 0;
    margin-top: $itemTop;
  }
}
