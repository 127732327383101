@import '~common/ui_imports';
@import '~bootstrap/scss/modal';

.modal {
  .modal-header {
    .close.btn-sm {
      font-size: 1rem;
    }

    .modal-title {
      color: $body-color;
    }
  }

  .modal-footer {
    &--space-between {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

.ui-modal-dialog {
  .modal-body {
    color: $body-color;
    font-size: 1rem;
    text-align: left;
  }

  .modal-header {
    display: flex;
    flex-direction: column;
    position: relative;

    .close {
      float: none; // Remove silly Bootstrap floats
      position: absolute;
      right: 1rem;
      top: 1rem;
    }
  }

  &__modal-title {
    font-family: $font-family-base;
    font-size: 1.125rem;
    font-weight: $font-weight-normal;

    margin: 0;
    padding: 0;
  }

  &__modal-subtitle {
    font-family: $font-family-base;
    font-size: $font-size-base;
    font-weight: $font-weight-normal;

    margin: .5rem 0 0;
    padding: 0;
  }

  &--centered {
    .modal-body {
      text-align: center;
    }

    .modal-header {
      align-items: center;
    }
  }
}
