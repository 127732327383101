@import '~common/ui_imports';

.form-page {
  margin: 0 auto;
  max-width: $form-max-width;
  padding: $ui-page-spacing-mobile 0;

  @include media-breakpoint-up(sm) {
    padding: $ui-page-spacing 0;
  }

  &--sm {
    max-width: $form-max-width-sm;
  }

  &--md {
    max-width: $form-max-width-md;
  }

  .card {
    border: none;
    box-shadow: var(--ux-box-shadow-card);
  }

  .FormGroup {
    .ReactSearchSelect {
      width: 100%;
    }
  }
}
