@mixin ui-full-view-height($lessHeight: 0, $lessHeightMobile: $lessHeight) {
  @if $lessHeight > 0 {
    height: calc(100vh - #{$lessHeight});
    min-height: calc(100vh - #{$lessHeight});
  } @else {
    height: calc(100vh);
    min-height: calc(100vh);
  }

  @include media-breakpoint-up(sm) {
    @if $lessHeightMobile > 0 {
      height: calc(100vh - #{$lessHeightMobile});
      min-height: calc(100vh - #{$lessHeightMobile});
    } @else {
      height: calc(100vh);
      min-height: calc(100vh);
    }
  }
}
