// This is meant to contain only the primary root/common styles like bootstrap
// component styles should live bundled alongside their js code

// Import this first so that our ui_overrides can extend the classes properly
@import 'bootstrap';

@import '~@fortawesome/fontawesome-svg-core/styles';
@import 'body';
@import '../branding/company_logos';
@import 'content_well';
@import 'fonts';
@import 'form_errors';
@import 'form_page';
@import 'form_well';
@import 'input_fields';
@import 'lists';
@import 'osano';
@import 'overlay';
@import 'padded_page';
@import 'page_section';
@import 'participant_footer';
@import 'popper';
@import 'react_select';
@import 'tabs';
@import 'synthesis_overrides';
@import '~@user-interviews/ui-design-system/scss/css_properties';

:root {
  --navigation-background-color: #010812;
  --navigation-item-active-background-color: #272D39;
  --navigation-item-border-width-thickness: 3px;
  --navigation-mobile-footer-height: 3.125rem;
  --navigation-mobile-header-height: 3.125rem;
  --navigation-text-color: #E2E2E2;
  --page-centered-width: 56rem;
  --page-header-height: 0;
  --page-initial-sidebar-width: 3.125rem;
  --page-padding-x: 2.25rem;
  --page-padding-y: 0;
  --z-index-ui-navbar: calc(var(--z-index-fixed) + 1);
}
