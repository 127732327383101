@import '../ui_imports';

.non-synthesis-participant-footer {
  @include make-row;
}

.participant-footer {
  position: sticky;
  top: 100vh;

  align-items: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  background-color: var(--ux-gray-100);
  padding: var(--synth-spacing-4) var(--synth-spacing-3);

  &__powered-by {
    font-weight: normal;
    padding-top: var(--synth-spacing-2);

    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      text-align: right;
      flex: 1 0;
    }

    &__logo {
      border-radius: 4px;
      margin: 0 0.5rem;
      padding: 0.5rem;
    }
  }

  &__terms {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;

    @include media-breakpoint-up(md) {
      flex: 0 0 auto;
      width: 50%;
    }

    p {
      @include media-breakpoint-up(md) {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      color: var(--ux-gray-700);

      &:hover {
        text-decoration: none;
      }
    }

    &__default_terms {
      a {
        margin-right: 1rem;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

.gray-100 {
  .participant-footer {
    background-color: var(--ux-white);
  }
}
