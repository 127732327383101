// https://github.com/JedWatson/react-select

@import '../ui_imports';

$select-input-border-focus: var(--ux-blue-300);
$select-input-height: 42px; // rem units don't work for this

$select-item-bg: var(--ux-blue-100);
$select-item-color: var(--ux-blue-700);
$select-item-font-size: 1rem;

$select-option-focused-bg: lighten($ux-blue, 40%);
$select-option-selected-bg: lighten($ux-blue, 80%);

$select-text-color: var(--ux-gray-800);

.react-select-container {
  .react-select__control {
    height: auto;
    min-height: $select-input-height;
    box-shadow: none;

    &.react-select__control--is-focused {
      border-color: $select-input-border-focus;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 8%);
    }
  }

  .react-select__multi-value {
    background-color: $select-item-bg;
    color: $select-item-color;
    border-radius: 4px;

    .react-select__multi-value__label {
      color: $select-item-color;
    }

    .react-select__multi-value__remove {
      color: var(--ux-blue);

      &:hover {
        background-color: $select-option-focused-bg;
        color: $select-item-color;
      }
    }
  }

  .react-select__option {
    color: var(--ux-gray-800);
    font-size: $select-item-font-size;
  }
}

// For some reason, there is no variable for this it seems?
.Select-option {
  font-size: 1rem;
}

.react-select__option.New-option {
  color: $ux-primary;
  font-weight: $font-weight-bold;
}

// Overriding the font weights
// TODO: RXS-2896 remove this once fixed in the DS
.Synthesized {
  .Select__placeholder,
  .Select__option,
  .Select__single-value {
    font-weight: 400;
  }
}
