@import '~common/ui_imports';

// leaving this named as-is until we can decide a better one
.page-section {
  &__controls {
    @include ui-fixed(bottom);

    display: flex;

    align-items: center;
    justify-content: center;

    background: var(--ux-white);
    box-shadow: var(--ux-box-shadow-top);
    height: $ui-ctabar-height-mobile;
    padding: 0;

    .btn-link {
      font-weight: $font-weight-bold;
    }

    @include media-breakpoint-up(sm) {
      height: $ui-ctabar-height;

      .btn {
        min-width: 12.5rem;
        margin: 0 2rem;
      }
    }
  }

  &__navigation {
    @include ui-page-row-margins;
  }
}
