@import '../../ui_imports';
@import '~bootstrap/scss/alert';

.alert {
  font-weight: 500;
}

.alert-error {
  @extend .alert-danger;

  background-color: $ux-flash-error;
  border-color: $ux-flash-error-border;
}
